import { React, styled, Link } from "../shipityo/index";
import Layout from "../layouts/index";

import PageHeading from "../helpers/page-heading";
import practiceAreas from "../helpers/practice-areas.json"
import Img from "gatsby-image";

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
`;

const ContentInner = styled.div`
    display: grid;
    justify-content: space-between;
    grid-gap: 3px;
    grid-template-columns: 1fr;
    padding-left: 90px;
    padding-right: 90px;
    @media only screen 
    and (max-width: 650px)  {
        grid-template-columns: 1fr;
    } 
    .button{
        text-align: center;
    }

    a {
        color:#4d4d4d;
        margin: 0px;
    }

    h3{
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 9px;
    }

    p{
        margin: 0px;
    }

    .attorney-item{
        img {
            margin: 0px;
            padding: 0px;
        }
    }

    .heading {
        margin-top: 30px;
    }

    .service-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
    }

    .service-item{
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 4px;
        background: #646464;
        border-bottom: solid #fce302 7px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 5px 10px 10px rgba(0, 0, 0, .30);
        :hover{
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .30) inset;
        }
        .hidden-headline{
            display: none;
        }
        :hover{
            cursor: pointer;
        }
        a {
            color: #fff;
            font-size: 1.3em;
        }
    }

    hr{
        margin: 0px;
    }

    .active{
        background: red;
    }

`;  

class Component extends React.Component {

    constructor({data}) {
        let  pageImages =[];
        super();
        this.state = {
        };
        this.render = this.render.bind(this);
        this.showDetail = this.showDetail.bind(this);
        if(data){
            if(data.allFile){
                if(data.allFile.edges){
                    pageImages = data.allFile.edges
                    this.state = {pageImages: pageImages};
                } 
            } 
        }
    }

    showDetail(e){
        console.log(e.target);
        //document.getElementById("succes").classList.add("hidden");
    }
  
    render() {
        //let self = this
        return (
            <Layout>
            <div>
                <div id="home-images">
                    {this.state.pageImages.map((image, index) => (
                        <div>
                            <Img key={index} sizes={image.node.childImageSharp.sizes} />
                        </div>
                    ))}
                </div>
                <Container>
                    <PageHeading>
                        <span className="heading-top">QUALIFIED & PROFESSIONAL</span><br/>
                        <br/>
                        <hr className="heading-line"/><br/>
                        <span className="heading-main">LITIGATION PRACTICE AREAS</span>
                    </PageHeading>
                    <ContentInner>
                        <div className="services-list">
                            {
                                practiceAreas.map(function(area, i){
                                    return <h3>
                                        <Link to={area.path}><div className="service-item">{area.name}</div></Link>
                                    </h3>
                                
                                })
                            }
                        </div>
                    </ContentInner>
                </Container>
            </div>
            </Layout>
        ) 
    }
  }


  export default Component;
  
  export const query = graphql`
    query ServicesQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/pagebanners/services/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1900) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  `